import React from 'react'
import {Legende, Spacer} from '../Elements'
import styled from 'styled-components';
import { colors } from '../../consts/style';
import  BtnPrimary from '../buttons/ButtonRounded';
import { FormattedMessage} from 'react-intl';
const Form = styled.form`
  display:flex;
  flex-direction:column;
  label {display:none;}
`;

const Input = styled.input`
display:block;
width:100%;
padding:.3em;
background-color:${colors.yellowLight};
border-color: ${colors.dark};
resize:vertical;
border-style: none;
margin-bottom:1.5rem!important;
`;
const Textarea = styled.textarea`
display:block;
width:100%;
padding:.3em;
background-color:${colors.yellowLight};
border-color: ${colors.yellow};
resize:vertical;
border-style: none;
margin-bottom:1.5rem!important;
`;

const ContactForm = ({invisible=false}) => 
(
	
		<Form 
			name="Formulaire de contact" 
			action="/succes" 
			method="POST" 
			data-netlify="true" 
			netlify-honeypot="bot-field" 
		>
		   
		   
		     	<Input type="hidden" name="bot-field" />
		    	<Input type="hidden" name="form-name" value="Formulaire de contact" />
			   
			    <label htmlFor="email">Email</label>
			    <Input placeholder="Email" type="text" name="email" id="email" required  />
			           
		        <label htmlFor="message">Message</label>
		        <Textarea name="message" placeholder="Message"id="message" rows="6" required />
			  
			    <div className="actions">
					   <FormattedMessage id="send">{txt =><BtnPrimary as="input" type="submit" value={txt} />}</FormattedMessage>
			    </div>
				<Spacer/>
			    <Legende>Note : Les données collectées via ce formulaire ont pour unique finalité de nous permettre de répondre à votre demande.</Legende>
		</Form>
)

export default ContactForm