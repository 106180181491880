import React from 'react';
import styled from 'styled-components';
import { colors,  space } from '../consts/style';
import Boop from './boop.js';
const Wrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
width:150px;
`
const Title = styled.span`
color:${colors.dark};
text-align: center;
line-height: 1.3;
font-size:1.4rem;
margin-top: ${space.half};
`

const IconeCircleWrap = styled.span`
  padding: 5rem;
  width:88px;
  height:86px;
  border-radius:100%;
  background: ${colors.blue};
  display:flex;
  align-items:center;
  justify-content:center;
`

const Icone = styled.span`
    display:block;
    
    margin: 0;
}   
`;

const IconePratique = ({pictoURL, title, width, height}) => {

return (
 <Wrapper> 
   <Boop scale={1.05} timing={200}><IconeCircleWrap>
  <Icone>
    <img src={pictoURL} alt={title} width={width} height={height}/>
  </Icone>
  </IconeCircleWrap></Boop>
  {title && <Title>{title}</Title>}
 
</Wrapper>
)
 }
export default IconePratique