import React, { Fragment} from 'react';
import { graphql } from 'gatsby';
import Link from '../components/ExtendedLink';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import { FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import { colors,  space } from '../consts/style';

import {
  PageWrapper,
  PageInner,
  SectionWrapper,
  Flex,
  FocusText,
  Text,
  GridBienvenue,
  SectionTitle,
  Legende,
  BgWrap,
  Grid2Col,
  ActiviteItem,
  ActiviteBtnWrapper,
  ActivitesWrapper,
  GroupesWrapper,
  GroupesDescription,
  GroupesItem,
  Spacer
} from '../components/Elements';
import  BtnPrimary  from '../components/buttons/ButtonRounded';
import Seo from '../components/Seo';
import Badge from '../components/badge';
import IconePratique from '../components/icone';
import LogosPartenaires from '../components/LogosPartenaires';
import Splash from '../components/Splash';
import Boop from "../components/boop"

export const indexQuery = graphql`
 query datoCmsAccueil($locale: String) {

    logoBolleneAventure: file(relativePath: { eq: "logo-bollene-aventure.png" }) {
        childImageSharp {
          fixed(width:204, quality:90) {
            ...GatsbyImageSharpFixed
          }
        }
    }

    logoPaiement: file(relativePath: { eq: "logo_paiement.png" }) {
        childImageSharp {
          fixed(width:291, quality:90) {
            ...GatsbyImageSharpFixed
          }
        }
    }
   
    imageGroupeCustom: file(relativePath: { eq: "chang-duong-Sj0iMtq_Z4w-unsplash.jpg" }) {
      childImageSharp {
        resize(width: 575, height: 300) {
          src
        }
        fluid(maxHeight: 300) {
          aspectRatio
          ...GatsbyImageSharpFluid
        }
      }
    }

    poster: file(relativePath: { eq: "FR1I7261.jpg" }) {
      childImageSharp {
        resize(width: 1900, height: 1200) {
          src
        }
       
      }
    }

    datoCmsAccueilPage(locale: {eq: $locale}){
      titreDeLaSectionBienvenue
      sousTitreDeLaSectionBienvenue
      texteDeLaSectionBienvenueNode {
        childMarkdownRemark {
          html
        }
      }
      imageDeLaSectionBienvenue {
        title
        fluid(maxHeight: 438, imgixParams: { fm: "jpg", auto: "compress",fit: "crop", h: "438", w:"925", }) {
            ...GatsbyDatoCmsFluid
        }
      }
      titreDeLaSectionGroupes
      sousTitreSectionGroupes
      groupeHomepage {
                id
                titre
                introduction
                guidapKey
                guidapKeyV2
                slug
                imagePrincipale {
                  fluid(maxHeight: 300, forceBlurhash: false, imgixParams: {  fit: "crop", w: "575", h: "300" , fm: "jpg", auto: "compress"  }) {
                  ...GatsbyDatoCmsFluid
                  }
                }
      }
      titreDeLaSectionActivites
      activitesHomepage {
          id
          titre
          introduction
          pictogramme {
            url
          }
          guidapKey
          guidapKeyV2
          slug
          imagePrincipale {
            fluid(maxHeight: 480, forceBlurhash: false, imgixParams: { fm: "jpg", auto: "compress",  fit: "crop", h: "480", w:"900" }) {
            ...GatsbyDatoCmsFluid
            }
          }
          extraBoutonBilletterie {
            ... on DatoCmsBoutonGuidap {
              model {
                apiKey
              }
              id
              lienDuBouton
              codeGuidap
              codeGuidapV2
              texteDuBouton
            }
          }
      }
      sectionLocalisation
      sectionBonCadeau
      imageBonCadeau { fixed(height: 244, imgixParams: {  auto: "compress" }) {
            ...GatsbyDatoCmsFixed
        }
      }
      titreDeLaSectionEnPratique
      texteDeLaSectionEnPratique
      texteDeLaSectionEnPratiqueSuite
      imagesSecurite  {
            fluid(maxHeight: 300, forceBlurhash: false, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
            }
            alt
          }
      iconesEnPratique {
        texteIconeLegende
        iconeImage {
          url
          width
          height
        }
      }
      logosPartenaires{
        lien
        logo {
          fixed(height: 54,  forceBlurhash: false, imgixParams: {  auto: "compress" }) {
          ...GatsbyDatoCmsFixed
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      
    }
    slides: datoCmsAccueilPage(locale: {eq: $locale}) {
      activitesHomepage {
          slug
          titre
          id
          pictogramme {
            url
          }
          introduction
          imagePrincipale {
            fluid(maxWidth: 600, forceBlurhash: false, imgixParams: { fm: "jpg", auto: "compress", fit: "crop", h: "480", w:"640" }) {
              ...GatsbyDatoCmsFluid
            }
          }    
      }
    }
    news: allDatoCmsActualite(filter: {locale: {eq: $locale}}, limit: 1, sort: {fields: meta___updatedAt, order:DESC}) {
      edges {
        node {
          id
          titre
          contenu
          image {
            
            fixed(width:321, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFixed
            }
          }
          guidapKey
          guidapKeyV2
          boutonLien {
            ... on DatoCmsBouton {
              model {
                apiKey
              }
              id
              lienDuBouton
              lienExterne
              boutonGuidap
              texteDuBouton
            }
          }
        }
      }
    }
  }
`;


const FlexBtnWrapper = styled(Flex)`
margin-top:2rem;
margin-bottom:3rem;
align-items:flex-start;
justify-content:center;
& > * {
  margin:${space.quarter} ${space.half};
}
`

const SectionGroupes = styled.div`
width:100%;
position:relative;
margin-top:1rem;
margin-bottom:5rem;
text-align:center;
`
const SectionWrapperLocalisation = styled(SectionWrapper)`
  margin-top: 7.5rem;
  margin-bottom: 5rem;
  text-align: center;
`
const Image = styled.div`
  align-self: flex-start;
  display: flex;
  grid-gap: 0.5rem;
  flex-direction: column;
  position: relative;
  align-self: flex-start;
  width: 49%;
`
const FlexImg = styled(Flex)`
   flex-wrap:nowrap;
  margin:1rem 0;
  grid-gap:1rem;
  align-items:flex-start;
  justify-content:space-between;
`

const FlexIconePratique = styled(Flex)`
  padding:2rem 0;
  flex-wrap:wrap;
  grid-gap:1rem;
  align-items:flex-start;
  justify-content:center;
`


const IndexPage = ({ data, pageContext }) => {
  //const handleClick = (guidapKey) => window.GUIDAP.booking.call('accroche-aventure.guidap.co', `${guidapKey}?lang=${pageContext.locale}`)


  /*const { openModal } = useContext(ModalContext);*/
  const {
    titreDeLaSectionBienvenue,
    sousTitreDeLaSectionBienvenue,
    texteDeLaSectionBienvenueNode,
    imageDeLaSectionBienvenue,

    titreDeLaSectionActivites,
    activitesHomepage,
    titreDeLaSectionGroupes,
    sousTitreSectionGroupes,
    groupeHomepage,
    sectionBonCadeau,
    imageBonCadeau,
    texteDeLaSectionEnPratique,
    texteDeLaSectionEnPratiqueSuite,
    titreDeLaSectionEnPratique,
    imagesSecurite,
    iconesEnPratique,
    sectionLocalisation,
    logosPartenaires,
    seoMetaTags,
   
  } = data.datoCmsAccueilPage;


  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
     <Splash slides={data.slides} news={data.news} poster={data.poster.childImageSharp.resize.src}/>
      <PageWrapper>
        <BgWrap curved color=" linear-gradient(180deg, rgba(232, 231, 231, 0) 36.96%, #F3F3F3 92.16%)">
          
          <SectionTitle centered dangerouslySetInnerHTML={{ __html: titreDeLaSectionBienvenue }} />         
          <FocusText centered  color={colors.blue}>{sousTitreDeLaSectionBienvenue}</FocusText> 
          <GridBienvenue>         
            <div>
              <Text dangerouslySetInnerHTML={{ __html: texteDeLaSectionBienvenueNode.childMarkdownRemark.html }}/>
              <FlexBtnWrapper>
              <Boop scale={1.02} timing={100} >
                <guidap-activity-buy-gift-button activity-uuid="qJ1yNdFGM724lfPv6rUVugtCc0HxIhpekzaY"><FormattedMessage id="home__bon-cadeau-aventure"/></guidap-activity-buy-gift-button></Boop>
                <BtnPrimary to="/horaires-tarifs"><FormattedMessage id="horaires-tarifs"/></BtnPrimary>
                <BtnPrimary to="/nos-activites"><FormattedMessage id="book"/></BtnPrimary> 
              </FlexBtnWrapper>
            </div>
            <div>
              <Img fluid={imageDeLaSectionBienvenue.fluid} alt={imageDeLaSectionBienvenue.title}/>
              <Legende>{imageDeLaSectionBienvenue.title}</Legende>
            
            </div>
          </GridBienvenue> 

        </BgWrap> 


        <SectionGroupes >
          <SectionTitle centered maxWidth dangerouslySetInnerHTML={{ __html: titreDeLaSectionGroupes }} />
          <FocusText color={colors.dark} dangerouslySetInnerHTML={{ __html: sousTitreSectionGroupes}}/>
          <GroupesWrapper type="homepage">
                { _map(groupeHomepage, (groupe, i) => (
                  <GroupesItem key={groupe.id} className={i===2 && "large"}>
                     <Link to={`/groupes/${groupe.slug}/`}>
                      <Img fluid={groupe.imagePrincipale.fluid} alt={groupe.titre}/>
                      <h3 className="title">{groupe.titre}</h3>
                      <GroupesDescription dangerouslySetInnerHTML={{ __html: groupe.introduction }}>
                        
                      </GroupesDescription>
                    </Link>
                  </GroupesItem>
                ))}
              <GroupesItem key="custom" className="custom">
                  <Link to="/acces-contact#contact">
                  <Img  fluid={data.imageGroupeCustom.childImageSharp.fluid} alt="Et vous ?"/>
                  <h3  className="title">Votre projet ?</h3>
                  <GroupesDescription><FormattedMessage id="contact-us"/> pour organiser l’évènement qui vous correspond : 100% adaptable, 100% personnalisable !</GroupesDescription>
                 
               </Link>
              </GroupesItem>
          </GroupesWrapper>
        </SectionGroupes>

<Spacer/>

<Spacer/>

        <PageInner>
        <SectionWrapper>
          <SectionTitle>{titreDeLaSectionActivites}</SectionTitle> 
          <ActivitesWrapper type="homepage">
                { _map(activitesHomepage, (activite, i) => (
                  <ActiviteItem key={activite.id} className={i===0 ? "big" : i===1 ? "large" : ""}>
                     <Link to={`/nos-activites/${activite.slug}/`}>
                      <Img fluid={activite.imagePrincipale.fluid} alt={activite.titre}/>
                      <div className="description">
                        <h3 className="title">{activite.titre}</h3>
                        <div className="badge" ><Badge pictoURL={activite.pictogramme.url}/></div>
                        <Text dangerouslySetInnerHTML={{ __html: activite.introduction }}/>
                      </div>

                    </Link>
                    <ActiviteBtnWrapper>
                    {/*  <BtnPrimary as="a" href="#" onClick={ ()=>handleClick(activite.guidapKey)}><FormattedMessage id="book"/></BtnPrimary> */}
                       <Boop scale={1.02} timing={100} ><guidap-activity-reserve-button activity-uuid={activite.guidapKeyV2}><FormattedMessage id="book"/></guidap-activity-reserve-button></Boop>

                       {activite.extraBoutonBilletterie.map(block => (
                          <React.Fragment key={block.id}>
                            {block.model.apiKey === "bouton_guidap" && (  
                              <>
                                                           {/*  <BtnPrimary as="a" href="#" onClick={()=>handleClick(block.codeGuidap)}>{block.texteDuBouton}</BtnPrimary>    */}

                                                            <Boop scale={1.02} timing={100} ><guidap-activity-reserve-button activity-uuid={block.codeGuidapV2}>{block.texteDuBouton}</guidap-activity-reserve-button></Boop>

                              </>  
                              )
                            }
                          </React.Fragment>
                        ))}
                    </ActiviteBtnWrapper>
                  </ActiviteItem>
                ))}
          </ActivitesWrapper>
          </SectionWrapper>
        </PageInner>
        
          <Spacer/>
          <BgWrap color={colors.blueLight}>
            <PageInner>
              <Flex>
                <div>
                  <h3><FormattedMessage id="home__bons-cadeaux"/></h3> 
                  <Text dangerouslySetInnerHTML={{ __html: sectionBonCadeau}}/>
                  <FlexBtnWrapper>
                   {/* <BtnPrimary as ="a" href="#" onClick={ ()=>handleClick('MDgAJiB0F9suCrzq5XTeRowSU2fc6PvIH8ny')}><FormattedMessage id="home__bon-cadeau-escape-game"/></BtnPrimary>  */}
                    <Boop scale={1.02} timing={100} ><guidap-activity-buy-gift-button activity-uuid="Bm4eGQdXEJbFWpPfTDHYxSkyI9wth6OaZzNC"><FormattedMessage id="home__bon-cadeau-escape-game"/></guidap-activity-buy-gift-button></Boop>
                  {/*  <BtnPrimary  as ="a" href="#" onClick={ ()=>handleClick('YASr7BmpIDeWcKyG1vxVCRizEZoJQPhH4gMa')}><FormattedMessage id="home__bon-cadeau-aventure"/></BtnPrimary> 	 */}
                    <Boop scale={1.02} timing={100} ><guidap-activity-buy-gift-button activity-uuid="qJ1yNdFGM724lfPv6rUVugtCc0HxIhpekzaY"><FormattedMessage id="home__bon-cadeau-aventure"/></guidap-activity-buy-gift-button></Boop>
                </FlexBtnWrapper>
                </div>
                <div>
                 <Img fixed={imageBonCadeau.fixed} alt={imageBonCadeau.titre}/>
                </div>
              </Flex>
            </PageInner>
          </BgWrap>
         
       

      <BgWrap curved color="linear-gradient(180deg, rgba(232, 231, 231, 0) 36.96%, #F3F3F3 92.16%);">
       <PageInner>
        <Grid2Col>
          <div>
            <SectionTitle dangerouslySetInnerHTML={{ __html: titreDeLaSectionEnPratique }} />
            <Text dangerouslySetInnerHTML={{ __html: texteDeLaSectionEnPratique}}/>
            <FlexImg>
              { _map(imagesSecurite, (image, i) => (
                  <Image key={i}>
                    <Img fluid={image.fluid} />
                    <Legende>{image.alt}</Legende>
                   </Image>
                ))}
            </FlexImg>
          </div>
          <div>
            <FlexIconePratique>
              { _map(iconesEnPratique, (icone, i) => (
                <IconePratique 
                  key ={i} 
                  pictoURL={icone.iconeImage.url}
                  title={icone.texteIconeLegende} 
                  width={icone.iconeImage.width}
                  height={icone.iconeImage.height}
                />
                ))}
            </FlexIconePratique>
          </div>
          <div>
           
            <Text dangerouslySetInnerHTML={{ __html: texteDeLaSectionEnPratiqueSuite}}/>
            <Img fixed={data.logoPaiement.childImageSharp.fixed}/>
          </div>
          </Grid2Col>
        </PageInner>
      </BgWrap>
      <SectionWrapperLocalisation>
        <FocusText centered maxWidth="560px" color={colors.dark} dangerouslySetInnerHTML={{ __html: sectionLocalisation}}/>
        <FlexBtnWrapper>
                <BtnPrimary to="/horaires-tarifs"><FormattedMessage id="horaires-tarifs"/></BtnPrimary>
                <BtnPrimary as="a" rel="noopener noreferer nofollow" href="https://www.google.com/maps/dir//Accroche+Aventure,+281+chemin+lafebre,+30760+Saint-Julien-de-Peyrolas/@44.2948528,4.5587574,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12b5a809e2c2cfe5:0xa857aeae633ec8b5!2m2!1d4.5609514!2d44.294849" target="_blank"><FormattedMessage id="btn__itineraire"/></BtnPrimary> 
                <BtnPrimary to="/nos-activites"><FormattedMessage id="book"/></BtnPrimary> 
        </FlexBtnWrapper>
        <Text ><FormattedMessage id="waiting for you"/></Text> 
        <Text ><FormattedMessage id="bollene aventure"/></Text> 
        <a href="https://www.bolleneaventure.com/" target="_blank" rel="noreferrer" title="Bollène Aventure"><Img fixed={data.logoBolleneAventure.childImageSharp.fixed} alt ="Bollène Aventure"/></a>
      </SectionWrapperLocalisation>
      
      <LogosPartenaires data={logosPartenaires}/>
      </PageWrapper>
     
    </Fragment>
  );
}


export default IndexPage