import React from 'react';
import { animated, useSpring } from 'react-spring';


const AnimatedNumber = (
	{numberToAnimate, springConfig = {
    	
	    mass: 50,
        tension: 50,
        friction: 15,
        clamp: true
  		}
  	}) => {

const props = useSpring({ 
	from: { number: 0, opacity:1 },
	to: {number:numberToAnimate, opacity:1},
	delay: 600,
	config:springConfig

   })


return ( 
	<animated.span style={props}>
		{props.number.to(n => Math.floor(n))}
	</animated.span>
)
 }


export default AnimatedNumber;