import React from 'react';
/*import Link from 'gatsby';*/

const Breadcrumb = ( {crumbs} ) => {
//console.log(crumbs);

  return (
    
       <div>
          <ul>
              {/*crumbs.map((crumb, index) => (
                  ((crumbs.length - index) > 1) ? <li key={index}><Link to={crumb.toLowerCase()}>{crumb}</Link></li> : <li key={index}>{crumb}</li> 
              ))*/}
          </ul>
          <div style={{ clear: 'both' }}></div>
      </div>
       
  )
}
export default Breadcrumb
/*
const Breadcrumb =  ({ breaddata }) => {
  var elementsNew = []

  breaddata.crumbs.forEach(createdBread);
  function createdBread(item) {
   var newlast = item.crumbLabel.replace(/-/g, " ");
   elementsNew.push({pathname: item.pathname, crumlabel: newlast});
 }
 console.log(elementsNew)


 return (

   <>
   <div className="row">
   <div className="col-md-12 shopintro">
<ul className="gjbread">
   {elementsNew.map((value, index) => {
     return  <li key={index} className="gjbreadcrumb"> 
     <Link key={index} to={`${value.pathname}`}>{value.crumlabel} <span> / </span> </Link>
     </li>
   })}
 </ul>
</div>
</div>
</>
 )
}
*/
