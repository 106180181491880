module.exports = {
  siteUrl: 'https://accroche-aventure.com',
  sitemapPath: '/sitemap.xml',
  title: 'Accroche Aventure',
  description: "Parc aventure aux portes des Gorges de l'Ardèche situé à la frontière du Gard, de l'Ardèche, de la Drôme et du Vaucluse : 12 parcours dans les arbres, passage en filet, avec +de 1500m de tyrolienne. Tout y est pour passer un moment inoubliable ! Une aventure à faire absolument en famille ou entre amis. Sensations et ambiance assurées !",
  author: '@oliviergenevest',
  locales: {fr:
    {
      path: 'fr',
      name: 'Français',
      default: true
    },
    en:
    {
      path: 'en',
      name: 'English'
    }
  },

};
