import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Img from 'gatsby-image';
import Seo from '../components/Seo';
import {
  PageWrapper,
  PageInner,
  PageTitle,

  Banner,
} from '../components/Elements';

const mentionsLegalesQuery = graphql`
  {
    
   banner: file(relativePath: { eq: "banner-test.jpg" }) {
      childImageSharp {
          fluid( maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }

      }
    }

    page: datoCmsPageMentionlegale {
      titre
      contenu
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;




export default function MentionsLegalesPage() {
  const data = useStaticQuery(mentionsLegalesQuery);
  const { titre, contenu, seoMetaTags } = data.page;

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>
        <Banner height="250px">
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>
        <PageInner>
          <PageTitle>{titre}</PageTitle>
          <div dangerouslySetInnerHTML={{ __html: contenu }} />
        </PageInner>
      </PageWrapper>
    </Fragment>
  );
}
