import React from 'react';
import styled from 'styled-components';
import { colors,  space } from '../consts/style';

const Wrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
width:150px;
`
const Title = styled.span`
color:${props => (props.color ? props.color : 'white' )};
text-align: center;
line-height: 1.3;
font-weight:500;
margin-top: ${space.half};
`

const Picto = styled.span`
    display:inline-block;
    border-radius:100%;
    cursor:pointer;
    background: ${colors.blue};
    border: 3px solid white;
    padding: 2rem;
    margin: 0;
    transition:all .35s ease;
    &:hover {
      text-decoration: none;
      background:  ${colors.yellow};
      color:white;
    }

    img {width:70px;height:70px;}

}
   
`;

const Badge = ({pictoURL, title, color}) => {
 
return (
 <Wrapper> 
  <Picto>
    <img src={pictoURL} width="70px" height="70px" alt={title ? title : 'picto activite'}/>
  </Picto>
  {title && <Title color={color}>{title}</Title>}
 
</Wrapper>
)
 }
export default Badge