import React, { Fragment, useRef } from "react"
import { graphql } from "gatsby"
import Link from "../components/ExtendedLink"
import Img from "gatsby-image"
import styled from "styled-components"
import _map from "lodash/map"
import LogoSVG from "../images/logo_bleu.png"
// import Swiper core and required components
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Keyboard, Navigation } from "swiper"
import "swiper/swiper-bundle.min.css"
/*import styleSwiper from "swiper/swiper.scss"
import styleNavigation from "swiper/components/navigation/navigation.scss"*/
import BtnPrimary from "../components/buttons/ButtonRounded"

import {
  FocusText,
  Legende,
  PageWrapper,
  PageInner,
  PageTitle,
  SectionTitle,
  Spacer,
  Text2Col,
  ArrowLeftLink,
  ArrowRightLink,
  ArrowLeftIcon,
  ArrowRightIcon,
  Text,
  BgWrap,
} from "../components/Elements"
import { colors, mq } from "../consts/style"

import Seo from "../components/Seo"
import Badge from "../components/badge"
import Boop from "../components/boop"

// install Swiper components
SwiperCore.use([Navigation, Keyboard])

const ArrowLeftLinkNav = styled(ArrowLeftLink)`
  position: absolute;
  bottom: 50%;
  left: 1rem;
  z-index: 1;
  cursor: pointer;
  transform: translate3d(0, 50%, 0);
  &.swiper-button-disabled {
    opacity: 0;
  }
`

const ArrowRightLinkNav = styled(ArrowRightLink)`
  position: absolute;
  bottom: 50%;
  right: 1rem;
  transform: translate3d(0, 50%, 0);
  z-index: 1;
  cursor: pointer;
  &.swiper-button-disabled {
    opacity: 0;
  }
`

const DiaporamaFullWidth = styled.div`
  z-index: 0;
  display: grid;
  .swiper-container {
    width: 100%;
    max-width: 100%;
    max-height: calc(100vh - 122px);
    min-height: 0;
    min-width: 0;
  }
  .swiper-slide .gatsby-image-wrapper {
    transition: all 0.4s ease;
    border-radius: 4px;
  }
  .swiper-slide-active .gatsby-image-wrapper {
    transition: all 0.4s ease;
  }

  .swiper-slide {
    transition: opacity 0.4s ease;
    width: auto;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  .swiper-slide-active {
    opacity: 1;
    transition: opacity 0.4s ease;
  }

  .swiper-button-next {
    display: none;
  }

  .swiper-button-prev {
    display: none;
  }
`

const Park = styled.div`
  width: 100%;
  background: ${colors.blueLight};
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding-bottom: 8rem;
  padding-top: 8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-bottom:4rem;
  ${mq.tablet` 
  grid-template-columns: 1fr;
  `}
`

const ParkLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const GridIntro = styled.div`
  height: 100vh;
  max-height: calc(100vh - 122px);
  width: 100%;
  background: ${colors.yellowLight};
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;

  ${mq.tablet` 
  grid-template-columns: 1fr;
  grid-gap:1rem;
  height:auto;
  max-height: 100%;
  `}
`

const GridIntroLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  & ${PageTitle} {
    text-align: center;
    maw-width: 650px;
  }
`

const EncartBtnWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`

const Ville = ({ data, pageContext, location }) => {
  const {
    titre,
    question,
    seoMetaTags,
    descriptionNode,
    distance,
    encart,
  } = data.ville
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <GridIntro>
        <GridIntroLeft>
          <PageTitle
            dangerouslySetInnerHTML={{
              __html: "Parc accrobranche autour de " + titre,
            }}
          />
          <FocusText color={colors.dark}>
            A seulement{" "}
            <span>
              {" "}
              <b>{distance} km</b>{" "}
            </span>{" "}
            de {titre}
          </FocusText>
          <EncartBtnWrapper>
          <BtnPrimary to="/acces-contact">Calculer mon itinéraire</BtnPrimary> 
{" "}
            <BtnPrimary to="/nos-activites">Découvrir</BtnPrimary>
          </EncartBtnWrapper>
        </GridIntroLeft>
        <DiaporamaFullWidth>
          <Swiper
            navigation
            spaceBetween={10}
            slidesPerView={1}
            keyboard
            grabCursor
            freeMode
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
            onInit={swiper => {
              swiper.params.navigation.prevEl = prevRef.current
              swiper.params.navigation.nextEl = nextRef.current
              swiper.navigation.update()
            }}
            style={{ width: "100%", height: "100%" }}
          >
            <SwiperSlide key="11">
              <Img fluid={data.ville.imagePrincipale.fluid} />
            </SwiperSlide>
            {_map(data.diaporamaAccrocheAventure.edges, image => (
              <SwiperSlide key={image.node.childImageSharp.id}>
                <Img fluid={image.node.childImageSharp.fluid} />
              </SwiperSlide>
            ))}
            <ArrowLeftLinkNav ref={prevRef}>
              <Boop scale={1.05} timing={200}>
                <ArrowLeftIcon title="Précédent" />
              </Boop>
            </ArrowLeftLinkNav>

            <ArrowRightLinkNav ref={nextRef}>
              <Boop scale={1.05} timing={200}>
                <ArrowRightIcon title="Suivant" />
              </Boop>
            </ArrowRightLinkNav>
          </Swiper>
        </DiaporamaFullWidth>
      </GridIntro>
      <PageWrapper>
        <Park>
          <ParkLeft>
            <div>
              <FocusText centered color="#161616" maxWidth="60%">
                Tout y est pour passer un moment inoubliable ! Une aventure à
                faire absolument en famille ou entre amis. Sensations et
                ambiance assurées !!
              </FocusText>
              {data.allActivites.activitesHomepage.map((activite, i) => (
                <Boop scale={1.05} timing={200} key={i}>
                  <Link to={`/nos-activites/${activite.slug}/`}>
                    <Badge
                      pictoURL={activite.pictogramme.url}
                      title={activite.titre}
                      color={colors.dark}
                    />
                  </Link>
                </Boop>
              ))}
            </div>
          </ParkLeft>
          <div>
            <SectionTitle>
              <span>Accroche Aventure</span>
            </SectionTitle>

            <Text>
              Parc aventure aux portes des Gorges de l'Ardèche situé à la
              frontière du Gard, de l'Ardèche, de la Drôme et du Vaucluse : 12
              parcours dans les arbres, passage en filet, avec +de 1500m de
              tyrolienne. Fini les mousquetons ! Le parc est équipé de lignes de
              vie continues 100% sécurité, système suisse (poulie Safe roller)
              et plein d'autres activités. Nous organisons aussi des journées
              team building pour les entreprises etc ...{" "}
            </Text>
            <br />
            <Text>
              A tester : le saut en chute libre Quick Jump et les plaisirs de la
              glisse en dévalant des pistes assis dans de grosses bouées Summer
              Tubing. A partir de 2 ans accompagné d'un adulte.{" "}
            </Text>
            <br />
            <Text>
              Parcours Ludo Educatif "L'arbre ce héros" pour petits et grands
              (jeu d'orientation sur la connaissance de l'arbre, pour apprendre
              en s'amusant et profiter de la nature)
            </Text>
            <EncartBtnWrapper>
              <BtnPrimary to="/">Découvrir</BtnPrimary>{" "}
              <BtnPrimary to="/nos-activites">Réserver</BtnPrimary>
            </EncartBtnWrapper>
          </div>
        </Park>

        <PageInner>
          <SectionTitle dangerouslySetInnerHTML={{ __html: `Que faire autour de ${titre}` }} />

          <Text dangerouslySetInnerHTML={{ __html: question }} />
          <Spacer />
          <Legende dangerouslySetInnerHTML={{ __html: encart }} />

          <Spacer />
        </PageInner>

        <BgWrap curved color="#F3F3F3">
          <PageInner>
            <SectionTitle
              dangerouslySetInnerHTML={{ __html: `A propos de ${titre}` }}
            />
            <Text2Col>
              <Text
                dangerouslySetInnerHTML={{
                  __html: descriptionNode.childMarkdownRemark.html,
                }}
              />
            </Text2Col>
          </PageInner>
        </BgWrap>

        <img
          src={LogoSVG}
          width="200px"
          alt="Logo Accroche Aventure"
          style={{ marginTop: "4rem" }}
        />
        <Spacer />
      </PageWrapper>
    </Fragment>
  )
}

export const villeQuery = graphql`
  query($slug: String!) {
    allActivites: datoCmsAccueilPage(locale: { eq: "fr" }) {
      activitesHomepage {
        slug
        titre
        pictogramme {
          url
        }
      }
    }

    ville: datoCmsVille(slug: { eq: $slug }) {
      titre
      distance
      encart
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      slug
      id
      imagePrincipale {
        fluid(
          maxWidth: 1980
          forceBlurhash: false
          imgixParams: { fm: "jpg" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      question
    }

    diaporamaAccrocheAventure: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativePath: { regex: "/diaporama/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            id
            fluid(maxWidth: 950, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Ville
