import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import _map from 'lodash/map';
import { FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { colors, mq} from '../consts/style';
import Seo from '../components/Seo';

import {
  PageWrapper,
  PageInner,
  PageTitle,
  BgWrap,
  Spacer,
  FocusText,
  Text
} from '../components/Elements';

export const decouvrirQuery = graphql`
 query  decouvrirQuery($locale: String) {
   
  
    page: datoCmsDecouvrirPage(locale: {eq: $locale}) {
      titre
      introduction
      listeActivite {
        categorie
        titre
        description
        siteInternet
        image  {
          fluid(maxHeight: 200, forceBlurhash: false, imgixParams: {  fit: "crop", w: "375", h: "200" , fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
          }
        }
      }
      
     
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;


const CardDecouvrir = styled.div `
width:33%;  
max-width:30%;
margin-bottom:3rem;
  border:1px solid #f1ecec;
  border-top:3px solid ${colors.yellow};
 
 ${mq.mobile`
 width:100%;
 max-width:100%;
`}
`;
const CardTitle = styled.div `
 font-weight:700;
 padding: 0 5px;
 
`;
const CardDescription = styled.div `
 font-size:smaller;
 line-height:1.3;
 padding: 0 5px;
 padding-bottom:5px;
`;


const WrapCards = styled.div `
  display:flex;
  /*gap:4rem;*/
  justify-content:space-between;
  flex-wrap:wrap;
`;





const DecouvrirPage = ({data}) => {
  const {titre, introduction, listeActivite, seoMetaTags } = data.page;
 

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>
  
        <PageInner>

          <PageTitle dangerouslySetInnerHTML={{ __html: titre}} />
          <Text>{introduction}</Text>
          <Spacer/>
          <FocusText><FormattedMessage id="decouvrir__activites"/></FocusText>
          <WrapCards>
            { 
            _map(listeActivite.filter(function(item) {
              return item.categorie === "Activité";
          }), (activite, i) => (
              <CardDecouvrir as="a" key={i} href={activite.siteInternet} target="blank" rel="nofollow noindex">
                <Img fluid={activite.image.fluid} />
                <CardTitle>{activite.titre}</CardTitle>
                <CardDescription>{activite.description}</CardDescription>
              </CardDecouvrir>
            
            ))
            }
            </WrapCards>
            </PageInner>
          <Spacer/>
          <BgWrap curved color=" linear-gradient(180deg, rgba(232, 231, 231, 0) 36.96%, #F3F3F3 92.16%)">
          <PageInner>

          <FocusText><FormattedMessage id="decouvrir__lieux"/></FocusText>
          <WrapCards>
            { 
            _map(listeActivite.filter(function(item) {
              return item.categorie === "Lieu";
          }), (activite, i) => (
              <CardDecouvrir as="a" key={i} href={activite.siteInternet} target="blank" rel="nofollow noindex">
                <Img fluid={activite.image.fluid} />
                <CardTitle>{activite.titre}</CardTitle>
                <CardDescription>{activite.description}</CardDescription>
              </CardDecouvrir>
            
            ))
            }
            </WrapCards>
            </PageInner>
            <Spacer/>
            </BgWrap>
          
          <PageInner>
          <FocusText><FormattedMessage id="decouvrir__hebergements"/></FocusText>
          <WrapCards>
            { 
            _map(listeActivite.filter(function(item) {
              return item.categorie === "Hébergement";
          }), (activite, i) => (
              <CardDecouvrir as="a" key={i} href={activite.siteInternet} target="blank" rel="nofollow noindex">
                <Img fluid={activite.image.fluid} />
                <CardTitle>{activite.titre}</CardTitle>
                <CardDescription>{activite.description}</CardDescription>
              </CardDecouvrir>
            
            ))
            }
            </WrapCards>

        </PageInner>
        

      </PageWrapper>
    </Fragment>
  );
}

export default DecouvrirPage