import React, { Fragment} from 'react';
import { graphql } from 'gatsby';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import Seo from '../components/Seo';
import  BtnPrimary  from '../components/buttons/ButtonRounded';
import styled from 'styled-components';
import { mq } from '../consts/style'; 
import { FormattedMessage} from 'react-intl';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  FocusText,
  Text,
  Spacer,
} from '../components/Elements';


const GroupeListWrapper =   styled.div`
display:flex;
flex-direction:column;
width:100%; 
/*gap:5rem;*/
margin-top:8rem;
`

const GroupeItem =   styled.div`
display:flex;
flex-direction:row;
width:100%;
margin-bottom:5rem;
/*gap:3rem;*/
${mq.mobile`
flex-direction:column;
gap:1rem;
`}
`

const GroupeItemImage =   styled(Img)`
display:flex;
margin-right:1.5rem;
${mq.mobile`
margin-left:0;
`}
flex-direction:row;
height:300px;
width:100%;
border-radius:4px;
border-top: 3px solid #f2e20f;
${mq.mobile`
height:auto;
`}
`

const GroupeItemContent =   styled.div`
display:flex;
margin-left:1.5rem;
${mq.mobile`
margin-left:0;
`}
flex-direction:column;
width:100%;
h2 {
  font-family: 'Wendy One';
    font-weight: 500;
}
`

const StyledBtnPrimary = styled(BtnPrimary)`
margin-top:1.2rem;
`


export const groupesQuery = graphql`
  query  groupesPageQuery($locale: String) {
    page: datoCmsGroupePage(locale: {eq: $locale}) {
      titre
      contenu
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    groupes: allDatoCmsGroupe(filter: {locale: {eq: $locale}}, sort: {fields: titre}){
      edges {
        node {
          id
          titre
          introduction
          slug
          imagePrincipale {
            fluid(maxHeight: 480, forceBlurhash: false, imgixParams: { fm: "jpg" , auto: "compress", fit: "crop", h: "480", w:"640"}) {
            ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;


const GroupesPage = ({ data }) => {

  const { titre, contenu, seoMetaTags } = data.page;
  const { edges } = data.groupes; // tous les événements de groupe


  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>   
        <PageInner>
          <PageTitle centered maxWidth dangerouslySetInnerHTML={{ __html: titre }}/>
         <FocusText centered  dangerouslySetInnerHTML={{ __html: contenu }} />
         <GroupeListWrapper>
            { _map(edges, (activite, i) => (
              <GroupeItem key={activite.node.id}>
                <GroupeItemImage  fluid={activite.node.imagePrincipale.fluid} alt={activite.node.titre}/>
                <GroupeItemContent>
                  <h2>{activite.node.titre}</h2>
                  <Text dangerouslySetInnerHTML={{ __html: activite.node.introduction }}/>
                  <StyledBtnPrimary to={`/groupes/${activite.node.slug}/`}><FormattedMessage id="more"/></StyledBtnPrimary>
                </GroupeItemContent>
              </GroupeItem>
            ))}
           
          </GroupeListWrapper>
          <Spacer/>
        </PageInner>
        <Spacer/>
      </PageWrapper>
    </Fragment>
  );
}

export default GroupesPage;