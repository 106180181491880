import React, { Fragment, useRef } from 'react';
import {  graphql } from 'gatsby';
import Link from '../components/ExtendedLink';
import Img from 'gatsby-image';
import styled from 'styled-components';
import _map from 'lodash/map';
import { FormattedMessage} from 'react-intl';
// import Swiper core and required components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard,  Navigation  } from 'swiper';
/*
import styleSwiper from 'swiper/swiper.scss';
import styleNavigation from 'swiper/components/navigation/navigation.scss';*/
import 'swiper/swiper-bundle.min.css';
import  BtnPrimary  from '../components/buttons/ButtonRounded';
import { Banner, PageWrapper, PageInner, PageTitle, Spacer, Text2Col, ArrowLeftLink, ArrowRightLink, ArrowLeftIcon, ArrowRightIcon,Text } from '../components/Elements';
import { colors, mq } from '../consts/style';
import { projetTypes } from '../types/propTypes';
import Seo from '../components/Seo';
import Badge from '../components/badge';
import Boop from '../components/boop';
import Breadcrumb from '../components/breadcrumb';

/*console.log({ styleSwiper,styleNavigation  })*/
// install Swiper components
SwiperCore.use([Navigation, Keyboard]);

const ArrowLeftLinkNav = styled(ArrowLeftLink)`
  position:absolute;
  bottom:50%;
  left:1rem;
  z-index:1;
  cursor:pointer;
  transform: translate3d(0, 50%, 0);
  &.swiper-button-disabled{
    opacity:0;
  }
`;

const ArrowRightLinkNav = styled(ArrowRightLink)`
  position:absolute;
  bottom:50%;
  right:1rem;
  transform: translate3d(0, 50%, 0);
  z-index:1;
  cursor:pointer;
  &.swiper-button-disabled{
    opacity:0;
  }
`;


const DiaporamaFullWidth = styled.div`
z-index:0;
display: block;
 .swiper-container { 
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
  
 }
  .swiper-slide .gatsby-image-wrapper  {
     transition:all .4s ease;
     border-radius:4px;
  }
  .swiper-slide-active .gatsby-image-wrapper {
    transition:all .4s ease;
  }

  .swiper-slide {
     transition:opacity .4s ease;
     width: auto;
  flex-shrink: 0;
  display: block;
  height: 100%;
  max-height: 100%;
  }
   .swiper-slide-active {
    opacity:1;
    transition:opacity .4s ease;
   }

  .swiper-button-next{  
    display:none;
  }

   .swiper-button-prev {
    display:none;
   }

`;



const PageInnerActivite = styled.div`
  width: 100%; 
  position:relative;
  display:grid;
  
  grid-template-columns: 2fr 1fr;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  grid-gap:5rem;

  ${mq.tablet` 
  grid-template-columns: 1fr;
  grid-template-columns:  minmax(0, 1fr);
  grid-gap:1rem;
  `
}
`;

const LeftCol = styled.div`
  z-index:0;
`;



const EncartWrap = styled.div`
    margin-top: -20vh;
    grid-area: 1 / 2 / auto / auto;
    position:relative;
    ${mq.tablet` 
    grid-area: 1 / 1 / auto / auto;
    margin-top: -10vh;
    `
  }
`;

const Encart =  styled.div`
  background: ${colors.yellowLight};
  background:linear-gradient(90deg, rgba(241,239,212,1) 5%, rgba(246,234,203,1) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: sticky;
  top: 150px;
  margin: 0px auto;
  text-align: left;
  max-width:500px;
  padding:3rem;
  margin-bottom:2rem;
  ${mq.tablet` 
    position: relative;
    top: 0;
    padding:2rem;
  `}

  
`;

const EncartTitle = styled.div`
  font-family: Wendy One;
  text-align:left;
  margin-top:-1rem;
  margin-bottom:1rem;
  font-size:2.2rem;
`;

const EncartPicto = styled.div`
    position:absolute;
    
    right:0;
    top:0;
    transform:translateY(-50%) scale(.75  );
    & > * {cursor:default!important;}
`;
const EncartText = styled.div`
   font-size:1.6rem;
   font-weight:500;
   line-height: 1.4;
   ul {
    list-style: none;
    padding-left:2rem;
      li { margin-bottom:.6rem;}
      li:before {
        content: "";
        color: ${colors.blue};
        font-weight: bold;
        display: inline-block; 
        margin-left: -20px;
        margin-right: 10px;
       border-radius:100%;
        width: 10px;
        height: 10px;
        background:${colors.yellow};
        border: 2px solid ${colors.dark};
      }
    }

`;

const EncartBtnWrapper = styled.div`
  position:relative;
  display:flex;
    gap:1rem;
  margin-top:2rem;
  flex-wrap: wrap;

`
const PageInnerSubMenu = styled(PageInner)`
 a {  
   padding-left:1.5rem;
  padding-right:1.5rem;
}
  ${mq.tablet` 
  a {  padding-left:0.5rem;
    padding-right:0.5rem;}
    `}
`


const HeaderActivites = styled.div`
  padding:.5rem;
  background:${colors.grey};
  
  & ${PageInnerSubMenu} {
    display:flex;
   /* gap:3rem;*/
    justify-content:center;
    text-transform:uppercase;
    font-size:1.4rem;
    color:${colors.dark};
    font-weight:500;
  
    & .active {font-weight:700;}
   
  }

  ${mq.mobile` 
  display:none;
`}
`;

const LineHeading = styled.div`

  margin-top:5rem;
  margin-bottom:3rem;
  overflow: hidden;
  text-align: center;

 &:before,
 &:after {
  background-color: ${colors.blue};
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
  margin-right:2rem;
  ${mq.tablet`
    display:none;
 `}
 }
 &:before {
  right: 2rem;
  margin-left: -50%;
 }
 &:after {
  left: 2rem;
  margin-right: -50%;
 }
 `



const Projet = ({ data, pageContext, location }) => {

  const { titre, titreAlt, pictogramme, seoMetaTags,diaporamaPhotos, description, encart, guidapKey, guidapKeyV2,extraBoutonBilletterie} = data.projet;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
//  const handleClick = (guidapKey) => window.GUIDAP.booking.call('accroche-aventure.guidap.co', `${guidapKey}?lang=${pageContext.locale}`)
  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <HeaderActivites>
        <PageInnerSubMenu>
          {data.allActivites.activitesHomepage.map( ( activite, i) =>   
            <Link  activeClassName="active" key={i} to={`/nos-activites/${activite.slug}/`}>{activite.titre}</Link>
          )}
        </PageInnerSubMenu>
      </HeaderActivites>
      <PageWrapper>
      <Banner height="50vh">
        <Img fluid={data.projet.imagePrincipale.fluid} />
      </Banner>
      <Breadcrumb crumbs={location} />
       {/* <Breadcrumb breaddata={pageContext.breadcrumb} /> */}
      <PageInnerActivite>
          <LeftCol>
          <PageTitle  dangerouslySetInnerHTML={{ __html: titreAlt }}/>
          <Text2Col><Text dangerouslySetInnerHTML={{ __html: description }}/></Text2Col>
          <Spacer/>
          <DiaporamaFullWidth>
          <Swiper
              navigation
              spaceBetween={10}
              slidesPerView={1}
             keyboard
             grabCursor
             freeMode
             breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 2,
                spaceBetween : 20,
              },
              // when window width is >= 1024px
              1024: { 
                slidesPerView: 1.2,
                spaceBetween : 20,
              },
            }}
              onInit={(swiper) => {
                      swiper.params.navigation.prevEl = prevRef.current;
                      swiper.params.navigation.nextEl = nextRef.current;
                      swiper.navigation.update();
                    }} 
              >
              {
                  _map(diaporamaPhotos, ( image) => (      
                    <SwiperSlide key={image.originalId}>
                 <Img fluid={image.fluid} />
                    </SwiperSlide>
                  )
                  )
                }

                      <ArrowLeftLinkNav ref={prevRef} >
                        <Boop scale={1.05}  timing={200} ><FormattedMessage id="previous">{txt =><ArrowLeftIcon title={txt}/>}</FormattedMessage></Boop>
                      </ArrowLeftLinkNav>
                      <ArrowRightLinkNav ref={nextRef} >
                      <Boop scale={1.05}  timing={200} ><FormattedMessage id="next">{txt =><ArrowRightIcon title={txt}/>}</FormattedMessage>
</Boop>
                      </ArrowRightLinkNav>
              </Swiper>
          </DiaporamaFullWidth>
        </LeftCol>

        <EncartWrap>
          <Encart>
            <EncartTitle>{titre}</EncartTitle>
            <EncartPicto> <Boop rotation={10}  timing={200} ><Badge pictoURL={pictogramme.url}/></Boop></EncartPicto>
            <EncartText  dangerouslySetInnerHTML={{ __html: encart }}/>
            <EncartBtnWrapper>
             {/* <BtnPrimary as="a" href="#" onClick={()=>handleClick(guidapKey)}><FormattedMessage id="book"/></BtnPrimary>  */} 
              <Boop scale={1.02} timing={100} >
                <guidap-activity-reserve-button activity-uuid={guidapKeyV2}><FormattedMessage id="book"/></guidap-activity-reserve-button>
              </Boop>

              { (guidapKey !== "uZJ9DpAOmFa3NbP7GvqhzYn5lxBiTXjRKd2t") && 
              <BtnPrimary to="/horaires-tarifs"><FormattedMessage id="horaires-tarifs"/></BtnPrimary>
              }

            {extraBoutonBilletterie.map(block => (
              <React.Fragment key={block.id}>
                {block.model.apiKey === "bouton_guidap" && (    
                 <>
                  {/*  <BtnPrimary as="a" href="#" onClick={()=>handleClick(block.codeGuidap)}>{block.texteDuBouton}</BtnPrimary> */}
                  <Boop scale={1.02} timing={100} ><guidap-activity-reserve-button activity-uuid={block.codeGuidapV2}>{block.texteDuBouton}</guidap-activity-reserve-button> </Boop>
                  </>
                  )
                }
              </React.Fragment>
            ))}
            </EncartBtnWrapper>
           
          </Encart>
          
        </EncartWrap>


        </PageInnerActivite>
        <Spacer/> <Spacer/>
        <div>
          <LineHeading>Découvrez également nos autres activités !</LineHeading>
          {data.allActivites.activitesHomepage.map( ( activite, i) =>   
            <Boop scale={1.05} timing={200} key={i} ><Link to={`/nos-activites/${activite.slug}/`}><Badge pictoURL={activite.pictogramme.url} title={activite.titre} color={colors.dark}/></Link></Boop>
          )}
        </div>
        <Spacer/>   <Spacer/> <Spacer/>
        <Spacer/>
      </PageWrapper>
    </Fragment>
  );
};

export const projectQuery = graphql`
  query($slug: String!, $locale: String!) {
    allActivites: datoCmsAccueilPage( locale: {eq: $locale}) {
      activitesHomepage {
          slug
          titre
          pictogramme {
            url
          }
      }
    }

    projet: datoCmsActivite(slug: { eq: $slug }, locale: {eq: $locale}) {
      titre
      titreAlt
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      encart
      description
      id
      introduction
      pictogramme {
        url
      }
      imagePrincipale {
        fluid(maxWidth: 1480, forceBlurhash: false, imgixParams: { fm: "jpg" , auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }    
      slug
      guidapKey
      guidapKeyV2
      diaporamaPhotos {
        originalId
        fluid (maxHeight:400, imgixParams: {fit: "crop", w: "600", h: "400", fm:"jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
        }
      }
      extraBoutonBilletterie {
        ... on DatoCmsBoutonGuidap {
          model {
            apiKey
          }
          id
          lienDuBouton
          codeGuidap
          codeGuidapV2
          texteDuBouton
        }
      }
    }
  }
`;

/*
REQUETE OPTIMALE A remettre quand pas trop de traffic

imagePrincipale {
        fluid(maxWidth: 1980, forceBlurhash: false, imgixParams: { fm: "jpg" , auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }   
diaporamaPhotos {
        originalId
        fluid (maxHeight:600, imgixParams: {fit: "crop", w: "800", h: "600", fm:"jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
        }
      }      
      
      
*/

Projet.propTypes = projetTypes;

export default Projet;
