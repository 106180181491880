import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import {useIntl} from 'react-intl';
/*Retourne le message du haut de page*/ 
/* Workaround to get useStaticQuery to work (should be directly in header.js but it didnt work...*/
const Message = () => {
  const data = useStaticQuery(graphql`
    query {
      
      topBannerFR : datoCmsTopBanner(locale: {eq: "fr"}) {
        topBannerMessage
      }
      topBannerEN : datoCmsTopBanner(locale: {eq: "en"}) {
        topBannerMessage
      }
    
      placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const intl = useIntl();
  const message = (intl.locale === 'en' ? data.topBannerEN.topBannerMessage : data.topBannerFR.topBannerMessage)
 
  return <span dangerouslySetInnerHTML={{ __html: message }}/>
}

export default Message
